.errorWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
}

.errorBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    gap: 15px;
    margin: 12px;
    padding: 30px;
    background: #FFFFFF;
    border-radius: 30px;
}