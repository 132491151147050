header {
    background: #FFFFFF;
    border-radius: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0px 4px 12px 0px #00000014;
}

header .close {
    display: flex;
    justify-content: flex-end;
    padding: 0 16px;
    padding-top: 12px;
}

header .close .back {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

header .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 24px;
    padding-bottom: 12px;
}

header .container .sum,
header .container .info {
    display: flex;
    flex-direction: column;
}

header .container .sum {
    justify-content: flex-end;
    gap: 10px;
}

header .container .sum p {
    padding: 0;
    margin: 0;
    color: #757575;
    font-size: 16px;
}

header .container .sum span {
    font-size: 32px;
    font-weight: 600;
}

header .container .info {
    justify-content: flex-end;
    align-items: end;
    gap: 10px;
}

header .container .info .timerBlock {
    display: flex;
    width: 70px;
    gap: 12px;
    padding: 3px 12px;
    color: #757575;
    align-items: center;
    border: 1px solid #757575;
    border-radius: 20px;
    font-size: 16px;
}

header .container .info .timerBlock span {
    padding-top: 2px;
}

header .container .info .id {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: #757575;
    padding-bottom: 4px;
}

.closeWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    padding: 0 15px;
    box-sizing: border-box;
}

.closeWrapper.show {
    opacity: 1;
    visibility: visible;

}

.closeBlock {
    background: white;
    padding: 24px;
    border-radius: 30px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
}

.warningWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: end;
    justify-content: center;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    padding: 0 15px;
    box-sizing: border-box;
}

.warningWrapper.show {
    opacity: 1;
    visibility: visible;

}

.warningBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    background: white;
    padding: 7px 24px;
    border-radius: 30px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    margin-bottom: 90px;
    width: 100%;
}

.warningBlock p span {
    font-weight: 600;
}

.warningBlock svg {
    width: 24px;
    height: 24px;
}

.closeBlock h2 {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
}

.closeButtons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.closeButtons button {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease;
}

.closeButtons button:first-child {
    background: #ddd;
    color: #333;
}

.closeButtons button:first-child:hover {
    background: #ccc;
}

.closeButtons button:last-child {
    background: linear-gradient(222.4deg, #FF7700 6.42%, #E10CFF 93.77%);
    color: white;
}

.closeButtons button:last-child:hover {
    background: linear-gradient(222.4deg, #E10CFF 6.42%, #FF7700 93.77%);
}

.amonat {
    color: #2B2B2A;
    font-family: "Inter", sans-serif;
}

.loading-pulse {
    width: 30%;
    height: 100%;
    background: rgba(143, 143, 143, 0.2);
    border-radius: 8px;
    animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        opacity: 0.4;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 0.4;
    }
}


@media screen and (max-width: 400px) {

    header .close {
        display: none;
    }

    header .container {
        padding: 0 18px;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    header .container .sum span {
        font-size: 28px;
    }

    header .container .sum {
        gap: 5px;
    }

    header .container .info {
        gap: 5px;
    }

    header .container .info .timerBlock {
        width: 64px;
        gap: 8px;
        padding: 4px 8px;
    }

    header .container .info .timerBlock span {
        padding-top: 1px;
    }

    header .container .info .id {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        color: #757575;
    }
}